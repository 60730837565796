<template>
  <section-container>
    <div class="anchor-wrapper">
      <h2 class="title">数百万主播都在用魔筷</h2>
      <div class="data-box">
        <div 
          v-for="(item, index) in data"
          :key="index"
          class="data-item"
        >
          <div class="num-line">
            <count-to 
              :ref="`count${index}`" 
              :key="index" 
              class="num" 
              :startVal="0" 
              :endVal="item.num" 
              :duration="2000"
              :autoplay="false"
            ></count-to>
            <!-- <div class="num">{{item.num}}</div> -->
            <div class="unit">{{item.unit}}</div>
            <div v-if="item.plus" class="plus">+</div>
          </div>
          <div class="text-line">{{item.text}}</div>
        </div>
      </div>
      <div 
        class="anchor-swiper"
        @mouseenter="onSwiperEnter"
        @mouseleave="onSwiperLeave"
      >
        <div class="swiper-arrow prev"></div>
        <div class="swiper-arrow next"></div>
        <swiper ref="swiper" :options="swiperOption">
          <swiper-slide 
            v-for="(item, index) in swiperData" 
            :key="index"
          >
            <div 
              class="anchor-item" 
              @mouseenter="onItemEnter(index)"
              @mouseleave="onItemLeave(index)"
            >
              <div class="mask" :class="item.hover ? 'mask-hover' : ''"></div>
              <img :data-src="item.img" alt="" class="main">
              <div v-show="!item.hover" class="info">
                <div class="name">
                  <img :data-src="platformIcons[item.platform]" alt="" class="icon">
                  <div class="text">{{item.name}}</div>
                </div>
                <div class="fans">
                  粉丝量 {{item.fans}}{{item.unit}}
                </div>
              </div>
              <div v-show="item.hover" class="info info-hover">
                <div class="name">
                  <img :data-src="platformIcons[item.platform]" alt="" class="icon">
                  <div class="text">@{{item.name}}</div>
                </div>
                <div class="desc">{{item.desc}}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>  
      </div>
    </div>
  </section-container>
</template>

<script>
import CountTo from 'vue-count-to'
import SectionContainer from '@/components/home/SectionContainer.vue'

export default {
  components: {
    CountTo,
    SectionContainer
  },
  data() {
    return {
      platformIcons: {
        ks: 'https://mktv-in-cdn.mockuai.com/16238344785388888.png',
        kg: 'https://mktv-in-cdn.mockuai.com/16238344785148568.png',
        sph: 'https://mktv-in-cdn.mockuai.com/16238344785336279.png'
      },
      swiperOption: {
        // notNextTick: true,
        slidesPerView: 5,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-arrow.next',
          prevEl: '.swiper-arrow.prev',
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        loop: true,
        on: {
          observerUpdate: () => {
            // console.log('observer update')
            const nodes = [...document.querySelectorAll('.anchor-swiper .swiper-slide-duplicate')]
            nodes.forEach(node => {
              node.firstChild.addEventListener('mouseenter', e => {
                console.log(e.target)
                node.firstChild.childNodes[3].style.display = '';
                node.firstChild.childNodes[2].style.display = 'none'
              })
              node.firstChild.addEventListener('mouseleave', e => {
                console.log(e.target)
                node.firstChild.childNodes[2].style.display = '';
                node.firstChild.childNodes[3].style.display = 'none'
              })
            })
          }
        }
      },
      data: [
        {
          num: 300,
          unit: '万',
          plus: true,
          text: '服务主播数',
        },
        {
          num: 10,
          unit: '万',
          plus: true,
          text: '累计服务专场次数'
        },
        {
          num: 180,
          unit: '万小时',
          plus: true,
          text: '平台主播月平均直播时长'
        }
      ],
      swiperData: [
        {
          name: '徐楠楠',
          fans: 1021.6,
          unit: '万',
          desc: '沉浸式的运营服务+全品类的供应链，魔筷让我卖货无忧！',
          img: 'https://mktv-in-cdn.mockuai.com/16276110285096669.png',
          platform: 'ks',
          hover: false
        },
        {
          name: '阿咪家真皮女鞋',
          fans: 446.2,
          unit: '万',
          desc: '从三亚魔筷年货节第一次合作，到帮助我成就公益事业，魔筷非常专业！',
          img: 'https://mktv-in-cdn.mockuai.com/16276110285059026.png',
          platform: 'ks',
          hover: false
        },
        {
          name: '陈先生·亲民选品官',
          fans: 2156.6,
          unit: '万',
          desc: `我最在意的就是让大家得到好物。与魔筷合作让我感受到“志同道合”！`,
          img: 'https://mktv-in-cdn.mockuai.com/16276110285021182.png',
          platform: 'ks',
          hover: false
        },
        {
          name: '小黄飞',
          fans: 1265.8,
          unit: '万',
          desc: '在我最需要时魔筷出现了，专业服务＋全品类供应链让我开启新征程！',
          img: 'https://mktv-in-cdn.mockuai.com/16276110284996252.png',
          platform: 'ks',
          hover: false
        },
        {
          name: '口吐芬芳毛愣姐',
          fans: 131,
          unit: '万',
          desc: '从经营十多年手机维修店到拥有百万多快手粉丝，只要坚持就一定能赢！',
          img: 'https://mktv-in-cdn.mockuai.com/16276110284946365.png',
          platform: 'ks',
          hover: false
        },
        {
          name: '夜听刘筱',
          fans: 120,
          unit: '万',
          desc: '感觉魔筷是一个高效、有温度、服务全面的优质合作平台！',
          img: 'https://mktv-in-cdn.mockuai.com/16276110284891833.png',
          platform: 'sph',
          hover: false,
        },
        {
          name: '石妞妞的山头',
          fans: 120,
          unit: '万',
          desc: '魔筷接地气又实在，始终为用户及创业者考虑，是值得信赖的合作伙伴！',
          img: 'https://mktv-in-cdn.mockuai.com/16276110284861661.png',
          platform: 'sph',
          hover: false,
        },
        {
          name: '文哥文嫂',
          fans: 78.5,
          unit: '万',
          desc: '2020年度盛典才艺男主播第9名',
          img: 'https://mktv-in-cdn.mockuai.com/16276110284827151.png',
          platform: 'kg',
          hover: false
        },
        {
          name: 'CS丛书',
          fans: 249.5,
          unit: '万',
          desc: '华语流行女歌手，2020年度盛典年度荣耀女王冠军',
          img: 'https://mktv-in-cdn.mockuai.com/16276110284747188.png',
          platform: 'kg',
          hover: false
        },
        {
          name: '假如',
          fans: 97,
          unit: '万',
          desc: '2020年度电商带货主播亚军',
          img: 'https://mktv-in-cdn.mockuai.com/16276110284659580.png',
          platform: 'kg',
          hover: false
        }
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper
    }
  },
  methods: {
    reloadCount() {
      this.data.forEach((item, index) => {
        console.log(this.$refs[`count${index}`], item, index);
        this.$refs[`count${index}`][0].start()
      })
    },
    onSwiperEnter() {
      this.swiper.autoplay.stop()
    },
    onSwiperLeave() {
      this.swiper.autoplay.start()
    },
    onItemEnter(index) {
      // console.log(index)
      this.$set(this.swiperData, index, {
        ...this.swiperData[index],
        hover: true
      })
    },
    onItemLeave(index) {
      this.$set(this.swiperData, index, {
        ...this.swiperData[index],
        hover: false
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../../style/common.less';

  .anchor-wrapper {
    box-sizing: border-box;
    height: 100%;
    padding: 0 6.097560975609756%;
  }
  h2.title {
    padding-top: 4.305555555555555%;
    margin-bottom: 1.3888888888888888%;
    text-align: center;
    line-height: 1.4;
    // font-size: 40px;
    font-size: 2.4390243902439024vw;
    font-weight: 600;
    color: #333
  }
  .data-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .data-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 14.583333333333334%;
      &:last-child {
        margin-right: 0;
      }
      .num-line {
        display: flex;
        align-items: flex-end;
        line-height: 1;
        .num {
          // line-height: 82px;
          // font-size: 68px;
          line-height: 1.1470588235294117;
          font-size: 4.146341463414634vw;
          font-family: DINCond-Light, DINCond;
          font-weight: 300;
          color: #333;
        }
        .unit, .plus {
          // line-height: 28px;
          // font-size: 20px;
          line-height: 1.4;
          font-size: 1.2195121951219512vw;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #333;
        }
        .unit {
          margin-bottom: 0.6097560975609756vw;
        }
        .plus {
          align-self: center;
        }
      }
      .text-line {
        color: #666;
        font-size: 1.2195121951219512vw;
        font-weight: 400;
      }
    }
  }
  .anchor-swiper {
    position: relative;
    // width:81.0416667vw;
    margin: 2.2222222222222223% auto 0;
    .swiper-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 3.658536585365854vw;
      height: 3.658536585365854vw;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      &.prev {
        left: -4.2682926829268295vw;
        background-image: url(https://mktv-in-cdn.mockuai.com/16267704795807034.png);
      }
      &.next {
        right: -4.2682926829268295vw;
        background-image: url(https://mktv-in-cdn.mockuai.com/16267704795397316.png);
      }
    }
    .anchor-item {
      position: relative;
      width: 16.585365853658537vw;
      height: 28.048780487804876vw;
      border-radius: 16px;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      &:hover {
        .mask {
          visibility: hidden;
        }
      }
      .mask {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 9, 42, .5);
        &:hover {
          background: linear-gradient(180deg, rgba(0, 9, 42, 0) 0%, rgba(0, 9, 42, 0.77) 100%);
        }
        &.mask-hover {
          background: linear-gradient(180deg, rgba(0, 9, 42, 0) 0%, rgba(0, 9, 42, 0.77) 100%);
        }
      }
      .main {
        width: 100%;
        object-fit: cover;
      }
      .info {
        box-sizing: border-box;
        position: absolute;
        // bottom: 0;
        .pxToVw(bottom, 7, 1640);
        left: 0;
        width: 100%;
        min-height: 6.097560975609756vw;
        // padding-left: 7.352941176470588%;
        padding: 0 7.352941176470588%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .name {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0.7926829268292683vw;
          .icon {
            width: 1.2195121951219512vw;
            height: 1.2195121951219512vw;
            margin-right: 0.24390243902439024vw;
          }
          .text {
            line-height: 1.4;
            font-family: PingFangSC-Medium, PingFang SC;
            // font-size: 20px;
            font-size: 1.2195121951219512vw;
            font-weight: 500;
          }
        }
        .fans {
          line-height: 1.4;
          // font-size: 18px;
          font-size: 1.0975609756097562vw;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
        }
        &.info-hover {
          height: auto;
          align-items: flex-start;
          .desc {
            line-height: 1.4285714285714286;
            // font-size: 14px;
            font-size: 0.8536585365853658vw;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1640px) {
    h2.title {
      font-size: 40px;
    }
    .data-box {
      .data-item {
        .num-line {
          .num {
            font-size: 68px;
          }
          .unit, .plus {
            font-size: 20px;
          }
          .unit {
            margin-bottom: 10px;
          }
        }
        .text-line {
          font-size: 20px;
        }
      }
    }
    .anchor-swiper {
      .swiper-arrow {
        width: 60px;
        height: 60px;
        &.prev {
          left: -70px;
        }
        &.next {
          right: -70px;
        }
      }
      .anchor-item {
        width: 272px;
        height: 460px;
        .info {
          height: 100px;
          // padding-left: 20px;
          padding: 0 20px;
          .name {
            margin-bottom: 13px;
            .icon {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
            .text {
              font-size: 20px;
            }
          }
          .fans {
            font-size: 18px;
          }
          &.info-hover {
            .desc {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    h2.title {
      font-size: 23.4146px;
    }
    .data-box {
      .data-item {
        .num-line {
          .num {
            font-size: 39.8049px;
          }
          .unit, .plus {
            font-size: 11.7073px;
          }
          .unit {
            margin-bottom: 5.85366px;
          }
        }
        .text-line {
          font-size: 11.7073px;
        }
      }
    }
    .anchor-swiper {
      .swiper-arrow {
        width: 35.109px;
        height: 35.109px;
        &.prev {
          left: -40.9756px;
        }
        &.next {
          right: -40.9756px;
        }
      }
      .anchor-item {
        width: 159.219px;
        height: 269.266px;
        .info {
          height: 58.531px;
          // padding-left: 11.703px;
          padding: 0 11.703px;
          .name {
            margin-bottom: 7.610px;
            .icon {
              width: 11.688px;
              height: 11.688px;
              margin-right: 2.341px;
            }
            .text {
              font-size: 11.7073px;
            }
          }
          .fans {
            font-size: 10.5366px;
          }
          &.info-hover{
            .desc {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
</style>
