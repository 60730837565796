<template>
  <section-container>
    <div class="store-wrapper">
      <h2 class="title">数万商家都在与我们合作</h2>
      <div class="data-box">
        <div v-for="(item, index) in storeData" :key="index" class="data-item">
          <div class="num-line">
            <count-to
              :ref="`count${index}`"
              :key="index"
              class="num"
              separator=""
              :startVal="0"
              :endVal="item.num"
              :duration="2000"
              :autoplay="false"
            ></count-to>
            <!-- <span class="num">{{item.num}}</span> -->
            <span v-if="item.unit" class="unit">{{ item.unit }}</span>
            <span v-if="item.plus" class="plus">+</span>
          </div>
          <div class="text-line">{{ item.text }}</div>
        </div>
      </div>
      <div class="store-swiper">
        <div class="swiper-arrow prev"></div>
        <div class="swiper-arrow next"></div>
        <swiper ref="storeSwiper" :options="storeSwiperOption">
          <swiper-slide v-for="(item, index) in storeSwiperData" :key="index">
            <div class="store-item" @click="onStoreChange(index)">
              <img
                data-src="https://mktv-in-cdn.mockuai.com/16244409915164987.png"
                alt=""
                class="play-icon"
              />
              <img :data-src="item.img" alt="" class="main" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="goods-box">
        <img
          data-src="https://mktv-in-cdn.mockuai.com/16391253176802848.png"
          alt=""
          class="logo"
        />
        <div class="split"></div>
        <div class="goods-swiper">
          <!-- <swiper :options="goodsSwiperOption">
            <swiper-slide
              v-for="(item, index) in goodsSwiperData"
              :key="index"
            >
              <div class="good-item">
                <img :src="item.img" alt="">
              </div>
            </swiper-slide>
          </swiper> -->
          <div class="goods-list">
            <div
              v-for="(item, index) in displayGoods"
              :key="index"
              class="good-item"
            >
              <img :data-src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
      <video-modal
        :visible="videoModalVisible"
        :src="currentVideo"
        @close="closeVideoModal"
      ></video-modal>
    </div>
  </section-container>
</template>

<script>
import CountTo from "vue-count-to";
import SectionContainer from "@/components/home/SectionContainer.vue";
import VideoModal from "@/components/VideoModal.vue";

export default {
  components: {
    CountTo,
    SectionContainer,
    VideoModal,
  },
  data() {
    return {
      // isRolling: true,
      currentVideo: "",
      videoModalVisible: false,
      storeSwiperOption: {
        // speed: 1800,
        // freeMode: true,
        slidesPerView: 3,
        spaceBetween: 21,
        // slidesPerGroup: 3,
        loop: true,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: ".swiper-arrow.next",
          prevEl: ".swiper-arrow.prev",
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        on: {
          click: () => {
            console.log(
              this.storeSwiper.activeIndex,
              this.storeSwiper.realIndex
            );
          },
          observerUpdate: () => {
            const nodes = [
              ...document.querySelectorAll(
                ".store-swiper .swiper-slide-duplicate"
              ),
            ];
            // console.log(nodes)
            nodes.forEach((node) => {
              let i = node.getAttribute("data-swiper-slide-index");
              // console.log(i)
              node.addEventListener("click", () => {
                this.onStoreChange(i);
              });
            });
          },
        },
      },
      storeData: [
        {
          num: 10000,
          plus: true,
          text: "服务商家",
        },
        {
          num: 100,
          unit: "万",
          plus: true,
          text: "在库SKU",
        },
        {
          num: 1000,
          plus: true,
          text: "入驻知名品牌",
        },
        {
          num: 1000,
          plus: true,
          text: "成就爆款",
        },
      ],
      storeSwiperData: [
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848765571.png",
          video: "https://cdn.mockuai.com/tms/official_kangbahe.mp4",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848784182.png",
          video: "https://cdn.mockuai.com/tms/official_haichijia.mp4",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848801338.png",
          video: "https://cdn.mockuai.com/tms/official_xumutang.mp4",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848825450.png",
          video: "https://cdn.mockuai.com/tms/official_dibolang.mp4",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848842428.png",
          video: "https://cdn.mockuai.com/tms/official_xiaoyang.mp4",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848866279.png",
          video: "https://cdn.mockuai.com/tms/official_heweifang.mp4",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848882792.png",
          video: "https://cdn.mockuai.com/tms/official_xianhuo.mp4",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848902970.png",
          video: "https://cdn.mockuai.com/tms/official_apojia.mp4",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848924664.png",
          video: "https://cdn.mockuai.com/tms/official_taoweixiong.mp4",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16391369848948288.png",
          video: "https://mktv-in-cdn.mockuai.com/16267449382268738.mp4",
        },

        {
          img: "https://mktv-in-cdn.mockuai.com/16391251780156337.png",
          video: "https://mktv-in-cdn.mockuai.com/16391061263268029.mp4",
        },

        {
          img: "https://mktv-in-cdn.mockuai.com/16391251780322593.png",
          video: "https://mktv-in-cdn.mockuai.com/16391061263403743.mp4",
        },

        {
          img: "https://mktv-in-cdn.mockuai.com/16391251780347557.png",
          video: "https://mktv-in-cdn.mockuai.com/16391061263362803.mp4",
        },

        {
          img: "https://mktv-in-cdn.mockuai.com/16391251780377973.png",
          video: "https://mktv-in-cdn.mockuai.com/16391061263338717.mp4",
        },
      ],
      goodsSwiperOption: {
        slidesPerView: 11,
        loop: true,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 500,
          disableOnInteraction: false,
        },
      },
      goodsSwiperData: [
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591585182413.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591585099839.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591585045055.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591585006300.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591584619117.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593337107330.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593337078461.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593337031088.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593336932835.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593336721408.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594850206877.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594850185517.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594850165824.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594850105240.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594849854000.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248596560138053.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248596560116326.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248596560099309.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248596559778884.png",
        },

        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781419990.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781376835.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781358593.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781303343.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781282352.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781068204.png",
        },
      ],
    };
  },
  computed: {
    storeSwiper() {
      return this.$refs.storeSwiper.$swiper;
    },
    displayGoods() {
      return this.goodsSwiperData.concat(this.goodsSwiperData);
    },
  },
  methods: {
    reloadCount() {
      this.storeData.forEach((item, index) => {
        console.log(this.$refs[`count${index}`], item, index);
        this.$refs[`count${index}`][0].start();
      });
    },
    onStoreChange(index) {
      // this.currentVideo = item.video
      this.currentVideo = this.storeSwiperData[index].video;
      this.videoModalVisible = true;
    },
    closeVideoModal() {
      this.videoModalVisible = false;
    },
    // play() {
    //   this.$refs.videoDom.play('btn')
    // },
    // pause() {
    //   this.$refs.videoDom.pause('btn')
    // },
    // stopRolling() {
    //   this.isRolling = false
    // },
    // startRolling() {
    //   this.isRolling = true
    // }
  },
};
</script>

<style lang="less" scoped>
@import "../../style/common.less";

@keyframes rolling {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}
.store-wrapper {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6.097560975609756%;
  // background-color: rgba(0, 9, 42, .93);
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-image: url(https://mktv-in-cdn.mockuai.com/16239157765191849.png);
}
h2.title {
  padding-top: 4.236111111111111%;
  margin-bottom: 1.3194444444444444%;
  line-height: 1.3888888888888888;
  text-align: center;
  font-size: 2.1951219512195124vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fff;
}
.data-box {
  display: flex;
  justify-content: center;
  align-items: center;
  .data-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 9.146341463414634vw;
    margin-right: 9.146341463414634vw;
    // margin-right: 12.5%;
    color: #fff;
    &:last-child {
      margin-right: 0;
    }
    .num-line {
      display: flex;
      align-items: flex-end;
      margin-bottom: 2px;
      .num {
        line-height: 1.1470588235294117;
        font-size: 4.146341463414634vw;
        font-family: DINCond-Light, DINCond;
        font-weight: 300;
      }
      .unit,
      .plus {
        line-height: 1.4;
        font-size: 1.2195121951219512vw;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
      }
      .unit {
        margin-bottom: 0.6097560975609756vw;
      }
      .plus {
        align-self: center;
      }
    }
    .text-line {
      font-size: 1.2195121951219512vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      opacity: 0.6;
    }
  }
}
.store-swiper {
  box-sizing: border-box;
  position: relative;
  margin-top: 2.8048780487804876vw;
  // margin: 3.07296666vw 5.208333vw 5.416667vw;
  // padding: 0 4.16666vw;
  .swiper-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3.658536585365854vw;
    height: 3.658536585365854vw;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    &.prev {
      left: -4.2682926829268295vw;
      background-image: url(https://mktv-in-cdn.mockuai.com/16250200463719781.png);
    }
    &.next {
      right: -4.2682926829268295vw;
      background-image: url(https://mktv-in-cdn.mockuai.com/16250200463422709.png);
    }
  }
  .swiper-container-free-mode {
    /deep/ .swiper-wrapper {
      transition-timing-function: linear !important; //swiper 匀速运动
    }
  }
  .store-item {
    position: relative;
    width: 28.414634146341463vw;
    height: 19.51219512195122vw;
    cursor: pointer;
    &:hover {
      .play-icon {
        visibility: visible;
      }
    }
    img.main {
      width: 100%;
      height: 100%;
    }
    .play-icon {
      visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3.658536585365854vw;
      height: 3.658536585365854vw;
    }
  }
}
.goods-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1.9444444444444444%;
  .logo {
    .pxToVw(width,100);
    .pxToVw(height,28);
    display: block;
  }
  .split {
    width: 1px;
    height: 3.658536585365854vw;
    margin: 0 2.0833333333333335%;
    background-color: #fff;
    opacity: 0.5;
  }
  .goods-swiper {
    position: relative;
    flex: 1;
    width: 87.43055555555556%;
    height: 4.878048780487805vw;
    overflow: hidden;
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 20;
      .pxToVw(width, 48, 1640);
      height: 100%;
    }
    &::before {
      left: 0;
      background: linear-gradient(270deg, rgba(0, 9, 42, 0) 0%, #00092a 100%);
    }
    &::after {
      right: 0;
      background: linear-gradient(270deg, #00092a 0%, rgba(0, 9, 42, 0) 100%);
    }
    .swiper-wrapper {
      transition-timing-function: linear !important;
    }
    .good-item {
      width: 4.878048780487805vw;
      height: 4.878048780487805vw;
      margin-right: 1.951219512195122vw;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .goods-list {
      animation: rolling 40s linear infinite;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      &.rolling {
        animation: rolling 27s linear infinite;
      }
      &:hover {
        animation-play-state: paused;
      }
    }
  }
}
@media screen and (min-width: 1640px) {
  h2.title {
    padding-top: 61px;
    margin-bottom: 19px;
    font-size: 36px;
  }
  .data-box {
    .data-item {
      margin-right: 150px;
      .num-line {
        .num {
          font-size: 68px;
        }
        .unit,
        .plus {
          font-size: 20px;
        }
        .unit {
          margin-bottom: 10px;
        }
      }
      .text-line {
        font-size: 20px;
      }
    }
  }
  .store-swiper {
    margin-top: 46px;
    .swiper-arrow {
      width: 60px;
      height: 60px;
      &.prev {
        left: -70px;
      }
      &.next {
        right: -70px;
      }
    }
    .store-item {
      width: 466px;
      height: 320px;
      .play-icon {
        width: 60px;
        height: 60px;
      }
    }
  }
  .goods-box {
    margin-top: 28px;
    .logo {
      width: 117px;
      height: 29px;
    }
    .split {
      width: 1px;
      height: 60px;
      margin: 0 30px;
    }
    .goods-swiper {
      width: 1259px;
      height: 80px;
      .good-item {
        width: 80px;
        height: 80px;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  h2.title {
    padding-top: 35.7031px;
    margin-bottom: 11.1094px;
    font-size: 21.0732px;
  }
  .data-box {
    .data-item {
      margin-right: 87.8963px;
      .num-line {
        .num {
          font-size: 39.8049px;
        }
        .unit,
        .plus {
          font-size: 11.7073px;
        }
        .unit {
          margin-bottom: 5.85366px;
        }
      }
      .text-line {
        font-size: 11.7073px;
      }
    }
  }
  .store-swiper {
    margin-top: 26.9268px;
    .swiper-arrow {
      width: 35.11px;
      height: 35.11px;
      &.prev {
        left: -40.9756px;
      }
      &.next {
        right: -40.9756px;
      }
    }
    .store-item {
      width: 272.766px;
      height: 187.312px;
      .play-icon {
        width: 35.11px;
        height: 35.11px;
      }
    }
  }
  .goods-box {
    margin-top: 16.375px;
    .logo {
      width: 68.4844px;
      height: 16.9688px;
    }
    .split {
      width: 0.625px;
      height: 35.1094px;
      margin: 0 17.5469px;
    }
    .goods-swiper {
      width: 736.984px;
      height: 46.8281px;
      .good-item {
        width: 46.8281px;
        height: 46.8281px;
      }
    }
  }
}
</style>
