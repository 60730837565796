<template>
  <div class="video-section">
    <video
      ref="bgVideo"
      preload
      data-autoplay
      autoplay
      loop
      muted
      width="100%"
      src="https://cdn.mockuai.com/tms/ebc008544c0aa641c28469dd2bd35ce1.mp4"
      poster="//mktv-in-cdn.mockuai.com/16285881975631695.png"
      class="video-bg"
    ></video>
    <div class="container">
      <h1 class="title">新消费品牌运营管理集团</h1>
      <div class="role-entrance">
        <div class="role-item" @click="gotoAnchor">
          <div class="info">
            <div class="title">主播合作</div>
            <div class="subtitle">我是/我想成为主播，想直播带货</div>
          </div>
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16237424069011494.png"
            alt=""
            class="icon"
          />
        </div>
        <div class="role-item" @click="gotoStore">
          <div class="info">
            <div class="title">商家合作</div>
            <div class="subtitle">我是品牌商/供应商，想让网红帮我带货</div>
          </div>
          <img
            data-src="https://mktv-in-cdn.mockuai.com/16237424069011494.png"
            alt=""
            class="icon"
          />
        </div>
      </div>
      <div class="company-introduction">
        <p>魔筷科技成立于2015年，是一家全球化的，集研发、生产、销售于一体的家庭消费品牌集团，旗下涵盖：品牌管理公司、海外品牌管理公司、供应链运营管理公司、文化传媒公司、技术公司等。</p>
        <p>公司始终秉持“为1亿家庭带来极致性价比的美好生活”的愿景，打造了数个年销售额过亿的家庭消费品牌，真正做到了源头工厂、品质保障、价格感人、渠道首选、客户满意。公司拥有强大的自主研发团队，专注于不断推陈出新，研发生产更符合现代家庭消费场景的产品。在销售渠道方面，公司依托强大的AI系统能力，于线上构建了70万中小主播的分发网络，旗下主播分布于全网各大平台，粉丝合计超10亿；于线下布局全国销售网络，自主品牌已入驻东北区、华中区、华东区、新零售等各大商超。同时公司不断打磨AI技术能力，对内实现了直播电商供应链线上化全流程标准化管理，对外不断输出丰富且强大的直播电商数字化工具产品矩阵。</p>
        <p>魔筷科技目前处于C2轮融资，先后获得了快手、腾讯、唯品会、钟鼎、众源、高榕等多家知名企业和机构的投资。</p>
      </div>
    </div>

    <div class="news-wrapper">
      <div class="news-container">
        <div class="title"></div>
        <div class="news-list">
          <swiper class="news-swiper" :options="swiperOption">
            <swiper-slide v-for="(item, index) in newsData" :key="index">
              <div class="news-item">
                <a
                  :href="item.link"
                  target="_blank"
                  @click="onNewsItemClick(item)"
                >
                  <div class="content">{{ item.text }}</div>
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <a href="/news" class="more" @click="jumpNews">查看全部</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  needPrefetch: true,
  data() {
    return {
      swiperOption: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        notNextTick: true,
        direction: "vertical",
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
      },
      newsData: [],
    };
  },
  async created() {
    try {
      const res = await axios.get(
        "https://www.mockuai.com/wp-json/wp/v2/posts?per_page=5&orderby=date&categories=9"
      );
      // console.log('新闻数据',res.data)
      this.newsData = res.data
        .filter((item) => item.title && item.title.rendered)
        .map((item) => ({
          text: item.title.rendered,
          link: `/article/${item.id}`,
        }));
      // console.log(this.newsData)
    } catch (error) {
      console.error(error || "获取新闻数据失败");
    }
  },
  methods: {
    tempClick(e) {
      e.preventDefault();
    },
    gotoAnchor() {
      console.log("anchor");
      // this.$router.push('/anchor')
      window.location.href = "/anchor";
      if (window._czc) {
        window._czc.push(["_trackEvent", "首页第一屏主播合作按钮", "点击"]);
      }
    },
    gotoStore() {
      // this.$router.push('/store')
      window.location.href = "/store";
      if (window._czc) {
        window._czc.push(["_trackEvent", "首页第一屏商家合作按钮", "点击"]);
      }
    },
    onNewsItemClick(item) {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "首页第一屏筷头条新闻链接",
          "点击",
          item.link,
        ]);
      }
    },
    jumpNews() {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "首页第一屏筷头条查看全部按钮",
          "点击",
        ]);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/common.less";

.video-section {
  box-sizing: border-box;
  position: relative;
  height: 100vh;
  padding-top: 100px;
  background-color: rgba(0, 9, 42, 0.3);
  .company-introduction {
    line-height: 26px;
    width: 48.5vw;
    color: #FFF;
    font-size: 15px;
    margin-top: 20px;
    p {
      text-indent: 2em;
    }
  }
  .video-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1.title {
    width: 75.5vw;
    // .pxToVw(width, 1200, 1640);
    // padding-top: 9.42708333vw;
    // margin: 0 auto 4.8958333vw;
    margin: 0 auto 44 * @indexVw;
    .pxToVw(font-size, 80, 1640);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: normal;
    color: #fff;
    text-align: center;
    // font-size: 4.166664vw;
  }
  .role-entrance {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    // margin: 0 25vw;
    width: 50vw;
    border-radius: 0.4166667vw;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(8px);
    // &::before {
    //   content: '';
    //   position: absolute;
    //   z-index: -1;
    //   top: -100px;
    //   left: -100px;
    //   right: -100px;
    //   bottom: -100px;
    //   background-color: rgba(255,255,255,.6);
    //   filter: blur(8px);
    // }
    &:hover {
      border: none;
      .role-item {
        &::after {
          content: "";
          width: 0;
        }
      }
    }
    .role-item {
      position: relative;
      box-sizing: border-box;
      height: 6.25vw;
      padding-left: 2.083333vw;
      padding-right: 2.36111vw;
      // flex: 0 0 25vw;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.4166667vw;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 128, 0, 0.8);
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 1px;
        height: 4.166664vw;
        background-color: #fff;
        opacity: 0.4;
      }
      &:last-child {
        &::after {
          content: "";
          width: 0;
          height: 0;
        }
      }
      .info {
        color: #fff;
        .title {
          margin-bottom: 0.138888vw;
          font-family: PingFangSC-Medium, PingFang SC;
          font-size: 1.5625vw;
          font-weight: 500;
        }
        .subtitle {
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 0.833333vw;
          font-weight: 400;
        }
      }
      .icon {
        width: 1.40625vw;
        height: 1.40625vw;
      }
    }
  }
  .news-wrapper {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 6.666667vw;
    // padding: 0 18.75vw;
    background-color: rgba(0, 9, 42, 0.3);
    .news-container {
      display: flex;
      align-items: center;
      width: 1200px;
      // width: 900px;
      height: 100px;
      margin: 0 auto;
    }
    .title {
      .pxToVw(width,72);
      .pxToVw(height,31);
      // line-height: 31px;
      margin-right: 47px;
      // font-family: HYYakuHei-FEW, HYYakuHei;
      // font-weight: normal;
      // font-size: 24px;
      // color: #fff;
      background-image: url(https://mktv-in-cdn.mockuai.com/16391249997612189.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .news-list {
      box-sizing: border-box;
      height: 100%;
      padding: 20px 0;
      flex: 1;
      .swiper-slide {
        height: 25px;
      }
      .news-swiper {
        height: 100%;
        .swiper-slide {
          display: flex;
          align-items: center;
        }
      }
      .news-item {
        margin-bottom: 0.902777vw;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #fff;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          // .split {
          //   width: 1px;
          //   height: 0.833333vw;
          //   background-color: #fff;
          //   margin: 0 0.833333vw;
          // }
          &:hover {
            // text-decoration: underline;
          }
        }
      }
    }
    .more {
      display: block;
      text-decoration: none;
      width: 88px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 4px;
      color: #fff;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        border: 1px solid #fff;
        background-color: #fff;
        color: #333;
        font-weight: 500;
      }
    }
  }
}
@media screen and (min-width: 1640px) {
  .video-section {
    h1.title {
      width: 1200px;
      font-size: 80px;
    }
    .company-introduction {
      width: 930px;
    }
    .role-entrance {
      width: 960px;
      .role-item {
        height: 120px;
        .info {
          .title {
            font-size: 30px;
          }
          .subtitle {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .video-section {
    h1.title {
      width: 750px;
      font-size: 50px;
    }
    .company-introduction {
      width: 570px;
    }
    .role-entrance {
      width: 600px;
      .role-item {
        height: 77px;
        .info {
          .title {
            font-size: 18.75px;
          }
          .subtitle {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
