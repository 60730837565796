<template>
  <div class="join-wrapper">
    <div class="join-container">
      <h2 class="title">欢迎入驻魔筷，与我们一同掘金直播电商</h2>
      <div class="join-box">
        <a target="_blank" href="/anchor" class="join-item" @click="onItemClick('主播合作')">
          <span class="text">主播合作</span>
          <span class="line"></span>
        </a>
        <a target="_blank" href="/store" class="join-item" @click="onItemClick('商家合作')">
          <span class="text">商家合作</span>
          <span class="line"></span>
        </a>
      </div>
    </div>
    <div class="footer">
      <p-footer type="flow" theme="transparent"></p-footer>
    </div>
  </div>
</template>

<script>
import Footer from '../../layout/Footer.vue'

export default {
  components: {
    'p-footer': Footer,
  },
  methods: {
    onItemClick(text) {
      if (window._czc) {
        window._czc.push(['_trackEvent', '首页第六屏角色跳转按钮', '点击', text]);
      }
    }
  }
}
</script>


<style lang="less" scoped>
  @import '../../style/common.less';

  .join-wrapper {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 90px;
    background-color: #00092a;
    background-image: url(//mktv-in-cdn.mockuai.com/16285640141343353.png);
    background-repeat: no-repeat;
    background-size: cover;
    .join-container {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      min-width: 960px;
      padding-top: 3.697916667vw;
    }
    h2.title {
      margin-bottom: 2.86458333vw;
      text-align: center;
      font-size: 2.083333vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fff;
    }
    .join-box {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 9.375vw;
      .join-item {
        // flex: 0 0 40.1046667vw;
        width: 40.1046667vw;
        height: 12.39583333vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        .pxToVw(border-radius, 16, 1640);
        color: #fff;
        background-color: #001357;
        background-size: cover;
        background-repeat: no-repeat;
        &:nth-child(1) {
          background-image: url(//mktv-in-cdn.mockuai.com/16286502266941067.png);
          &:hover {
            background-image: url(https://mktv-in-cdn.mockuai.com/16250520741369690.png);
          }
        }
        &:nth-child(2) {
          background-image: url(//mktv-in-cdn.mockuai.com/16275393552296636.png);
          &:hover {
            background-image: url(https://mktv-in-cdn.mockuai.com/16275394627109337.png);
          }
        }
        &:last-child {
          margin-right: 0;
        }
        .text {
          margin-bottom: 0.36458333vw;
          line-height: 2.916667vw;
          font-size: 2.083333vw;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
        }
        .line {
          width: 1.6667vw;
          height: 0.208333vw;
          background-color: #fff;
        }
      }
    }
    .footer {
      // flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-top: 97px;
    }
  }
  @media screen and(max-width: 960px) {
    .join-wrapper {
      .join-container {
        padding-top: 32px;
      }
      h2.title {
        margin-bottom: 27.5px;
        font-size: 20px;
      }
      .join-box {
        width: 770px;
        margin: 0 auto;
        .join-item {
          width: 379.65277777777777px;
          height: 119px;
          .text {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media screen and(min-width: 960px) and (max-width: 1440px) {
    .join-wrapper {
      .join-box {
        .join-item {
          width: 40.97222222222222vw;
          margin-right: 1.3888888888888888vw;
        }
      }
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1640px) {
    .join-wrapper {
      .join-box {
        width: 1200px;
        margin: 0 auto;
        .join-item {
          // width: 591.6666666666666px;
          width: 590px;
          height: 238px;
          margin-right: 20px;
        }
      }
    }
  }
  @media screen and (min-width: 1640px) {
    .join-wrapper {
      h2.title {
        font-size: 40px;
      }
      .join-box {
        width: 1440px;
        margin: 0 auto;
        .join-item {
          width: 710px;
          height: 238px;
          .text {
            font-size: 40px;
          }
        }
      }
    }
  }
</style>

